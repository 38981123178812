<template>
	<div style="background-color: #ffffff;padding: 10px;">
		<el-form ref="form" :model="skill" label-width="80px">
			<el-row>
				<el-col :span="6">
					<el-form-item label="从业资格"><el-input v-model="skill.qualify"></el-input></el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="职称"><el-input v-model="skill.rank"></el-input></el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="擅长"><el-input type="textarea" v-model="skill.goodAt" autosize></el-input></el-form-item>
					<el-form-item label="介绍"><el-input type="textarea" v-model="skill.introduce" autosize></el-input></el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-form-item label="附件图片">
					<el-upload
						class="upload-demo"
						drag
						:file-list="fileList"
						:headers="header"
						:action="baseURL + '/base-api/file/upload-with-filename'"
						multiple
						:on-success="handleAvatarSuccess"
					>
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">
							将文件拖到此处，或
							<em>点击上传</em>
						</div>
					</el-upload>
				</el-form-item>
			</el-row>
			<el-row>
				<el-form-item><el-button type="primary" @click="onSubmit(1)">保存</el-button></el-form-item>
			</el-row>
		</el-form>

		<div class="listContent">
			<div class="listContentTitle">
				<div>服务项目</div>
				<el-button type="primary" @click="openDialog">增加一项</el-button>
			</div>
			<div style="padding:10px;color:#333333" v-if="serviceList.length > 0">
				<div class="border-card-item">
					<div class="border-card-item-title">服务图片</div>
					<div class="border-card-item-title">服务类型</div>
					<div class="border-card-item-title">服务项目</div>
					<div class="border-card-item-title ">服务价</div>
					<div class="border-card-item-title border-right">操作</div>
				</div>
				<div class="border-card-item" :class="i == serviceList.length - 1 ? 'border-bottom' : ''" v-for="(item, i) in serviceList" :key="i">
					<div class="border-card-item-l"><el-image :src="item.image ? photoURL + item.image : ''" class="img"></el-image></div>
					<div class="border-card-item-r">{{ item.skillType == 1 ? '诊断服务' : '维修服务' }}</div>
					<div class="border-card-item-r">{{ item.name }}</div>
					<div class="border-card-item-r">￥{{ item.price }}</div>
					<div class="border-right border-card-item-r" style="color: #2388FF; ">
						<span style="margin-right: 20px; cursor: pointer;" @click="deleteItem(item.id)">删除</span>
						<!-- <span style="cursor: pointer;" v-if="i == serviceList.length - 1" @click="dialogVisible = true">增加一行</span> -->
					</div>
				</div>
			</div>
			<el-empty v-if="serviceList.length == 0" description="请新增服务项目"></el-empty>
		</div>
		<el-dialog title="新增项目" :visible.sync="dialogVisible" width="30%">
			<span slot="footer" class="dialog-footer">
				<el-form ref="forsm" :model="from" label-width="100px">
					<el-row>
						<el-col :span="10">
							<el-form-item label="图片*">
								<el-upload class="avatar-uploader" v-loading="loading2" action="" :show-file-list="false" :before-upload="BeforeUpload" :http-request="Upload">
									<el-image v-if="from.image" :src="photoURL + from.image" fit="fill" class="avatar"></el-image>
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="10">
							<el-form-item label="技能类型*">
								<el-select v-model="from.skillType" placeholder="请选择技能类型">
									<el-option label="诊断" value="1"></el-option>
									<el-option label="维修" value="2"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="20">
							<el-form-item label="服务项目*"><el-input v-model="from.name"></el-input></el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="20">
							<el-form-item label="价格*"><el-input v-model="from.price"></el-input></el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit(2)">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import caches from '@cache/cache.js';
import { getHeaderJson } from '@config/md5.js';
export default {
	data() {
		return {
			skill: {
				qualify: '',
				rank: '',
				goodAt: '',
				introduce: '',
				attachmentList: []
			},
			header: {},
			serviceList: [],
			fileList: [],
			dialogVisible: false,
			from: {
				skillType: '',
				name: '',
				price: '',
				image: ''
			},
			loading2: false,
			newFile: new FormData()
		};
	},
	mounted() {
		this.get(1);
		this.get(2);
		this.header = getHeaderJson(caches.token.get());
	},
	methods: {
		//上传前校验
		BeforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 10;
			const fileType = file.name.substring(file.name.lastIndexOf('.'));
			if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png'&& fileType != '.jpeg'&& fileType != '.JPEG') {
				this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
			} else if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
			} else {
				this.currentFileName = file.name;
				if (file) {
					if (this.newFile.has('file')) {
						this.newFile.delete('file');
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		//手动上传
		Upload() {
			this.loading2 = true;
			this.$upload(this.newFile)
				.then(res => {
					if (res.code == 1000) {
						this.from.image = res.data;
						this.loading2 = false;
						this.newFile = new FormData();
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(error => {
					this.$message.error(error);
				});
		},
		openDialog(){
			this.from ={};
			this.dialogVisible = true
		},
		onSubmit(e) {
			if (e == 1) {
				this.$postJson('/shop-api/mall/expert/skill/save', this.skill).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg);
						return;
					}
					this.$message.success(data.msg);
					this.get(1);
				});
			} else {
				this.$postJson('/shop-api/mall/expert/service/save', this.from).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg);
						return;
					}
					this.$message.success(data.msg);
					this.dialogVisible = false;
					this.get(2);
				});
			}
		},
		get(e) {
			if (e == 1) {
				this.$get('/shop-api/mall/expert/skill/get').then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg);
						return;
					}
					this.skill = {
						qualify: data.data.qualify,
						rank: data.data.rank,
						goodAt: data.data.goodAt,
						introduce: data.data.introduce,
						attachmentList: data.data.attachmentList
					};
					this.fileList = data.data.attachmentList.map(item => {
						return {
							name: item,
							url: item
						};
					});
				});
			} else {
				this.$get('/shop-api/mall/expert/service/list').then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg);
						return;
					}
					this.serviceList = data.data;
				});
			}
		},
		deleteItem(id) {
			this.$post('/shop-api/mall/expert/service/delete', { id: id }).then(data => {
				if (data.code != 1000) {
					this.$message.error(data.msg);
					return;
				}
				this.$message.success(data.msg);
				this.get(2);
			});
		},
		handleAvatarSuccess(res, file) {
			if (res.code == 1000) {
				this.skill.attachmentList.push(res.data);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.listContent {
}
.listContentTitle {
	width: 100%;
	padding: 10px;
	width: 100%;
	font-size: 16px;
	background: #fcfcfc;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.border-card-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 80px;

	.img {
		height: 76px;
		width: 76px;
		padding: 0;
		margin: 0;
	}
	.border-card-item-l{
		flex: 1;
		border-top: 1px solid #333333;
		border-left: 1px solid #333333;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.border-card-item-r{
		flex: 1;
		line-height: 80px;
		text-align: center;
		border-top: 1px solid #333333;
		border-left: 1px solid #333333;
	}
}
.border-card-item-title {
	flex: 1;
	font-weight: bold;
	line-height: 80px;
	border-top: 1px solid #333333;
	border-left: 1px solid #333333;
	text-align: center;
}
.border-right {
	border-right: 1px solid #333333;
}
.border-bottom {
	border-bottom: 1px solid #333333;
}
::v-deep.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
::v-deep.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 138px;
	height: 138px;
	line-height: 138px;
	text-align: center;
}
.avatar {
	width: 138px;
	height: 138px;
	display: block;
}
</style>
